import HelperImg from 'shared/assets/images/chat/HelperImg.webp'
import DotIcon from 'shared/assets/icons/DotIcon.webp'

import type { ManagerProps } from '../../../types/manager'
import { managerComments } from '../../../model/manager'

import cls from '../../QuestionsPage.module.scss'

export const Manager = (props: ManagerProps) => {
  const { paramsId, isMobile } = props

  return (
    <div className={cls.card}>
      <img className={cls.card__manager} src={HelperImg} alt="HelperImg" />
      <div className={cls.card__container}>
        <div className={cls.card__title_container}>
          <p className={cls.card__title}>Кайрат</p>
          <picture className={cls.card__dot}>
            <img src={DotIcon} alt="DotIcon" />
          </picture>
          <p className={cls.card__desc}>
            {isMobile ? 'Менеджер Freedom' : 'Менеджер Freedom Finance'}
          </p>
        </div>
        <p className={cls.card__message}>{managerComments[paramsId - 1]}</p>
      </div>
    </div>
  )
}
