type resultsDataType = {
  id: number
  fullName: string
  transactionNumber: string
  transactionValue: string
  transactionTime: string
}

const kzClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Абдрахманов К.С.',
    transactionNumber: 'Дивиденды ***3248',
    transactionValue: '+100 543 KZT',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Тлеубаев А.М.',
    transactionNumber: 'Дивиденды ***5496',
    transactionValue: '+120 987 KZT',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Сарсенбаев Б.К.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+200 321 KZT',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Жаксыбеков Л.А.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+175 489 KZT',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Куанышбаев М.С.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+310 234 KZT',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Утегенов Р.Г.',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+245 678 KZT',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Нурмагамбетов Х.С.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+185 432 KZT',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Темирбаев А.У.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+325 678 KZT',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Абдукалиев О.Р.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+150 987 KZT',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Сырлыбаев А.Т.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+280 321 KZT',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Токтаров Е.Д.',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+185 432 KZT',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Байбосынов А.С.',
    transactionNumber: 'Дивиденды ***3621',
    transactionValue: '+310 234 KZT',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Смагулов Д.А.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+200 321 KZT',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Абдуллаев Б.О.',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+120 987 KZT',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Жумабаев Д.Н.',
    transactionNumber: 'Дивиденды ***7512',
    transactionValue: '+325 678 KZT',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Капаров Ш.Р.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+150 987 KZT',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Темірбеков С.Ф.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+280 321 KZT',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Садуахасов У.Х.',
    transactionNumber: 'Дивиденды ***3102',
    transactionValue: '+185 432 KZT',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Темірбеков А.М.',
    transactionNumber: 'Дивиденды ***1786',
    transactionValue: '+310 234 KZT',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Токтарбеков Р.А.',
    transactionNumber: 'Дивиденды ***6975',
    transactionValue: '+120 987 KZT',
    transactionTime: '08:42',
  },
]

export const data = kzClients
